[data-section="2"] {

  @include media-breakpoint-up(md) {
    min-height: 100%;
  }

  .big-headline {

    img {
      @include media-breakpoint-up(md) {
        width: 120px;
      }

      @include media-breakpoint-up(lg) {
        width: 195px;
      }
    }
  }

  h1 {
    font-size: 1.4rem;
    line-height: 1.3;
    text-transform: none;
  }
}
