[data-section="3"] {

  .big-headline {

    img {
      @include media-breakpoint-up(md) {
        width: 260px;
        margin-top: -27px;
      }
    }
  }

  .graphic-text-right {
    background-color: #fff;
    color: #2d3643;
    border-radius: 6px;
    margin-top: 2rem;
    padding: 1.5rem;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
      padding: 3rem;
    }

    ul {
      margin-bottom: 2rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 4rem;
      }
    }

    h3 {
      font-family: 'helvetica-neue-lt-pro-bold';
      @include font_dimension_h5();
    }

    figure {
      margin-bottom: 2rem;
    }

    h2 + * {
      margin-top: 1.5rem;

      @include media-breakpoint-up(md) {
       margin-top: 3rem;
      }
    }
  }

  .graphic-text-container {
    margin-top: 0;

    @include media-breakpoint-up(md) {
      margin-top: 100px;
    }

    &>* {
      margin-top: 50px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
  }

  .graphic-text-bottom {
    position: relative;

    &::before {
      content: '';
      width: 10px;
      height: 50px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      right: 0;
      top: -50px;

      @include media-breakpoint-up(md) {
        height: 100px;
        top: -100px;
      }
    }

    img {
      width: 35%;
      @include media-breakpoint-up(md) {
        width: 50%;
        }
      }

    &>div {
      padding: 2rem 1rem 1rem 1rem;
      background-color: #fff;
      color: $main-blue;
      border-radius: 6px;
      height: 100%;

      &> h3 {
        text-align: center;

        @include font_dimension_h2();

        & + * {
          margin-top: 1.5rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .text-content {
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }

  & > .container > .row:nth-child(2) {
    display: block;
  }
}