[data-section="1"] {
  min-height: 60vh;

  background-position: center top;

  @include media-breakpoint-up(md) {
    height: calc(100vh - 60px);

    &.small {
      height: calc(50vh - 60px);
    }
  }

  & > .container > .row {
    display: flex;
  }

  .anker {
    bottom: 0;
    >* {
      background-color: transparent;
      padding-top: 2rem;
    }
  }

  .col-6 img {
    @include media-breakpoint-up(md) {
      width: 120%;
    }

    @include media-breakpoint-up(xl) {
      width: 140%;
    }
  }
}

.bounce {
  animation: bounce 1.5s ease-in-out infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1rem);
  }
}
