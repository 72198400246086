[data-section="4"] {

  .big-headline {

    img {
      @include media-breakpoint-up(md) {
        width: 130px;
      }

      @include media-breakpoint-up(lg) {
        width: 155px;
      }
    }
  }

  .project-overview {
    &>div {
      background-color: $dark-blue;
      border-radius: 8px;
      text-transform: uppercase;
      text-align: center;
      padding: 0.5rem;
      margin-top: 2rem;
      margin-bottom: 1.25rem;

      @include media-breakpoint-up(md) {
        margin-top: 4rem;
      }

      h3 {
        margin: 0;
        color: #fff;
      }
    }
  }

  & > .container > .row:nth-child(2) {
    display: block;
    margin-top: 0;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }
}