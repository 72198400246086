@mixin font_dimension_standard {
  font-size: 1rem;
  line-height: 1.25rem;
}

@mixin font_dimension_small {
  font-size: .8rem;
  line-height: 1.25rem;
}

@mixin font_dimension_eyecatcher_headline {
  font-size: 2rem;
  line-height: 2.2rem;

  @media (min-width: 25em) {
    font-size: 3rem;
    line-height: 3.2rem;
  }
  @media (min-width: 62em) {
    font-size: 4rem;
    line-height: 4.1rem;
  }
}

@mixin font_dimension_h1 {
  font-size: 1.5rem;
  line-height: 1.65rem;

  @media (min-width: 25em) {
    font-size: 2rem;
    line-height: 2.2rem;
  }
  @media (min-width: 62em) {
    font-size: 3rem;
    line-height: 3.2rem;
  }
}

@mixin font_dimension_h2 {
  font-size: 1.25rem;
  line-height: 1.45rem;

  @media (min-width: 25em) {
    font-size: 1.5rem;
    line-height: 1.65rem;
  }
}

@mixin font_dimension_h3 {
  font-size: 1.25rem;
  line-height: 1.45rem;

  @media (min-width: 25em) {
    font-size: 1.3rem;
    line-height: 1.65rem;
  }
  @media (min-width: 62em) {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

@mixin font_dimension_h4 {
  font-size: 1.13rem;
  line-height: 1.25rem;

  @media (min-width: 25em) {
    font-size: 1.25rem;
    line-height: 1.45rem;
  }
  @media (min-width: 62em) {
    font-size: 1.5rem;
    line-height: 1.65rem;
  }
}

@mixin font_dimension_h5 {
  font-size: 1rem;
  line-height: 1.25rem;

  @media (min-width: 25em) {
    font-size: 1.15rem;
    line-height: 1.25rem;
  }
  @media (min-width: 62em) {
    font-size: 1.25rem;
    line-height: 1.45rem;
  }
}

@mixin font_dimension_h6 {
  font-size: 1rem;
  line-height: 1.25rem;

  @media (min-width: 25em) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  @media (min-width: 62em) {
    font-size: 1.15rem;
    line-height: 1.25rem;
  }
}
