h1 {
  @include font_dimension_h1();
}

h2 {
  @include font_dimension_h2();

  font-weight: 600;
}

h3 {
  @include font_dimension_h3();

  font-weight: 600;
}

h4 {
  @include font_dimension_h4();
}

h5 {
  @include font_dimension_h5();
}

h6 {
  @include font_dimension_h6();
}

[data-section] {
  .big-headline {
    .headline {
      display: block;
      font-weight: normal;
      text-transform: uppercase;

      span {
        font-family: 'helvetica-neue-lt-pro-bold', sans-serif;
        font-size: 2rem;
        line-height: 2.2rem;

        @include media-breakpoint-up(md) {
          font-size: 2.5rem;
          line-height: 2.7rem;
        }

        @include media-breakpoint-up(xl) {
            font-size: 5rem;
            line-height: 5.1rem;
        }
      }
    }

    p.subheadline {
      text-transform: uppercase;
      margin-bottom: 0;
    }

    img {
      width: 20%;

      @include media-breakpoint-up(md) {
        width: 100%;
      }
    }

    & + * {
      margin-top: 2rem;

      @include media-breakpoint-up(md) {
        margin-top: 4rem;
      }
    }

    figure {
      @include media-breakpoint-up(md) {
        margin-left: 1.5rem;
      }
    }
  }

   .headline {
    &, & + p.subheadline {
      @include font_dimension_h2();
    }
  }

  &:nth-child(even) {
    .big-headline {
      figure {

        @include media-breakpoint-up(md) {
          margin-left: 0;
          margin-right: 1.5rem;
        }
      }
    }
  }

  .font-weight-bold {
    font-family: helvetica-neue-lt-pro-bold;
  }
}

span.mighty-case {
  text-transform: none;
}
