[data-section="6"] {
  .big-headline {

    img {
      width: 30%;

      @include media-breakpoint-up(md) {
        width: 100%;
      }

      @include media-breakpoint-up(lg) {
        width: 210px;
        margin-top: 0;
      }
    }
  }

  .col-md-4 {
    margin-top: 2rem;
    &>div {
      background-color: #f29592;
      padding-top: 100%;
      position: relative;
      display: flex;
      justify-content: center;

      figure {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        padding: 2rem;
      }
    }
  }

  .logo-container {
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }


  .section-title {
    margin-bottom: 0;
    text-transform: uppercase;
    font-family: helvetica-neue-lt-pro-bold;

    font-weight: 600;

    @include font_dimension_h3();
  }

  hr {
    margin-top: 0.25rem;
  }
}
