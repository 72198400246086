[data-section="7"] {

  .big-headline {

    img {
      @include media-breakpoint-up(md) {
        width: 120px;
      }

      @include media-breakpoint-up(lg) {
        width: 180px;
      }
    }
  }

  .col-12 > .text-center {
    color: #fff;
    background-color: #616e7d;
    border-radius: 6px;
    padding: 0.5rem;
    text-transform: uppercase;

    &>* {
      margin: 0;
    }

    & + * {
      margin-top: 2rem;
      margin-bottom: 0;

      & + * {
        margin-top: 0.5rem;
      }
    }
  }

  .col-md-4 {
    margin-top: 2rem;
    &>div {
      background-color: #f29592;
      padding-top: 100%;
      position: relative;
      display: flex;
      justify-content: center;

      figure {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        padding: 2rem;
      }
    }
  }

  .logo-container {
    margin-top: 2rem;

    @include media-breakpoint-up(md) {
      margin-top: 4rem;
    }
  }
}
