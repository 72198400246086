footer {
  font-size: 16px;
  color: #fff;

  .dotted {
    & + * {
      margin-top: 2rem;
    }
  }

  .container-fluid {
    background-color: #494d57;

    &:first-child {
      padding: 4rem 0 3.5rem 0;

      a[href] {
        color: $red;
      }
    }
  }

  .container-fluid:last-child {
    padding: 1.5rem 0 1rem 0;
    background-color: $blue;

    @include media-breakpoint-up(md) {
      padding: 1rem 0;

    }

    a[href] {
      color: #fff;
      display: block;
      width: 100%;
      padding: 0.5rem 0;

      @include media-breakpoint-up(md) {
        padding:  0;
      }
    }

    ul {
      padding: 0;
      margin-top: 1rem;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }

    li {
      //text-transform: uppercase;
      list-style: none;

      &:not(:first-child) {
        margin-left: 0;

        @include media-breakpoint-up(md) {
          margin-left: 1rem;
        }
      }
    }
  }

  .container .row {
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 1fr minmax(10rem, 50rem) 1fr;
      grid-template-areas: "earth content .";

      &>:first-child {
        grid-area: earth;
      }

      &>:nth-child(2) {
        grid-area: content;
      }
    }
  }


  .footer-content {
    .headline {
      font-size: 1.4rem;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        @include font_dimension_h4();
      }
    }

    &:first-child {
      .headline {
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }

    &:last-child {
      .headline {
        display: none;

        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
    }
  }
}
