[data-section] {
  //min-height: calc(100vh - 56px);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-bottom: 4rem;

  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 60px);
  }

  &.bg-dark {
    color: #fff;
  }

  &.bg-red {
    color: #fff;
    background-color: $red;
  }

  &.bg-light-blue {
    background-color: $light-blue;

    .big-headline h2,
    p.subheadline {
      color: #616e7d;
    }
  }

  position: relative;

  .anker {
    position: absolute;
    bottom: -0.75rem;
    z-index: 1;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(md) {
      bottom: -1.2rem;
    }

    &>* {
      background-color: #000;
      background-image: url("../icons/angle-arrow-down.svg");
      background-size: auto 70%;
      background-repeat: no-repeat;
      background-position: center;
      padding: 0.75rem 2rem;
      border-radius: 8px;

      @include media-breakpoint-up(md) {
        padding: 1.2rem 2.8rem;
        background-size: auto 80%;
      }
    }

    &--light-grey {
      &>* {
        background-color: #9a9ca2;
      }
    }

    &--light-red {
      &>* {
        background-color: #f6b2b2;
      }
    }

    &--light-blue {
      &>* {
        background-color: $lighter-blue;
      }
    }

  }

  .small-content {
    max-width: 768px;
    display: flex;
    flex-wrap: wrap;
  }

  & + [data-section] {
    padding: 4rem 0;
    @include media-breakpoint-up(md) {
      padding: 10rem 0;
    }
  }

  .img-mobile {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .img-desktop {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  li {
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }



  /*
  @include media-breakpoint-down(sm) {
    .big-headline > * {
      position: relative;

      figure {
        position: absolute;
        right: $grid-gutter-width / 2;
        top: 0;

        width: 5rem;
      }
    }
  }*/

}
