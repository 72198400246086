/*!
Theme Name: Fraunhofer
Author: meap GmbH
Author URI: https://meap.de
Text Domain: fraunhofer
 */

$blue: #373946;
$gray-800: $blue;
$dark-blue: #646d7c;

//$theme-colors: (
//  "custom-dark": $custom-dark
//);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px
);

$main-blue: #2d3643;
$light-blue: #aaddf8;
$lighter-blue: #d3ecfb;
$red: #ed6a66;

@import "~bootstrap/scss/bootstrap";
@import "~aos/dist/aos";
@import "mixin";

@font-face {
  font-family: 'helvetica-neue-lt-pro-bold';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../font/helvetica-neue-lt-pro-bold.woff') format('woff');
}

@font-face {
  font-family: 'helvetica-neue-lt-pro-light';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../font/helvetica-neue-lt-pro-light.woff') format('woff');
}

body {
  color: #2d3643;
  font-family: 'helvetica-neue-lt-pro-light', sans-serif;

  @include media-breakpoint-up(lg) {
    font-size: 1.2rem;
  }
}

ul {
  padding-left: 20px;
}

li {
  list-style-type: square;
}

@import "navigation";

.dotted {
  height: 2px;
  background-image: linear-gradient(90deg, #fff, #fff 75%, transparent 75%, transparent 100%);
  background-size: 20px 1px;
  border: none;
}

figure {
  margin: 0;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@import "typo";

@import "data-section/data-section-basic";
@import "data-section/data-section-1";
@import "data-section/data-section-2";
@import "data-section/data-section-3";
@import "data-section/data-section-4";
@import "data-section/data-section-5";
@import "data-section/data-section-6";
@import "data-section/data-section-7";


@import "footer";
