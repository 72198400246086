[data-section="5"] {

  .big-headline {

    img {
      @include media-breakpoint-up(md) {
        width: 100px;
      }

      @include media-breakpoint-up(lg) {
        width: 160px;
      }
    }
  }

  .innovation-container {
    margin-top: 2rem;

    &>.row {
      @include media-breakpoint-up(md) {
        padding-left: 1.5rem;
      }
    }

    img {
      width: 100%;
    }

    ul {
      margin-top: 1rem;
    }

    .d-flex + p {
      margin-top: 1rem;

      @include media-breakpoint-up(md) {
        margin-left: -1.75rem;
      }
    }

    .dotted {
      margin-top: 2rem;
      margin-bottom: 0;
    }

    .innovation-number {
      //@include font_dimension_eyecatcher_headline();
      font-family: helvetica-neue-lt-pro-bold;
      font-size: 3.25rem;
      line-height: 0.85;

      margin-right: 1.25rem;
      @include media-breakpoint-up(md) {
        margin-left: -1.75rem;
        margin-right: 1.25rem;
      }
    }

    .innovation-figure {
      > div, figure {
        @include media-breakpoint-up(md) {
          width: 100%;
        }
      }
    }

    h3 {
      @include font_dimension_h6();
      line-height: 1.55rem;

      font-family: helvetica-neue-lt-pro-bold;
      font-weight: 300;
    }

    figure {
      @include media-breakpoint-up(md) {
        padding-right: 4rem;
      }
    }

    &:nth-child(even) {
      figure {

        @include media-breakpoint-up(md) {
          padding-right: 0;
          padding-left: 4rem;
        }
      }
    }

    .data-section-5-distance-left {
      @include media-breakpoint-up(md) {
        margin-left: -1.75rem;
      }
    }

    &--1 {
      & > .row {
        padding-left: 0;
      }

      figure {
        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
          padding-right: 2.5rem;
        }
      }
    }

    &--2 {
      & > .row > .col-12 + .d-flex {
        margin-top: 2rem;
      }

      figure {
        margin-bottom: 1rem;
      }
    }
  }

  .innovation-container:last-child hr {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
