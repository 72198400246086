body > header {
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
}

.navbar {
  padding: 0.5rem 0;

  @include media-breakpoint-up(md) {
    padding: 0.5rem 1rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 1rem;

    display: grid;
    grid-template-columns: 2fr minmax(10rem, 50rem);
    grid-template-areas: "logo content";

    & > :first-child {
      grid-area: logo;
    }

    & > :nth-child(2) {
      grid-area: content;
    }
  }

  @include media-breakpoint-up(xl) {
    grid-template-areas: "logo  content content";
    grid-template-columns: 1fr minmax(10rem, 50rem) 1fr;

    .navbar-brand {
      grid-area: logo;
    }

    .justify-content-md-end {
      grid-area: content;
    }

  }

  @include media-breakpoint-down(md) {
    .navbar-nav {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .navbar-toggler {
    outline-color: $red;
  }

  .navbar-brand-group {
    width: 150px;
    position: relative;

    height: 2rem;

    @include media-breakpoint-up(lg) {
      height: 100%;
      width: 100%;
    }

    .navbar-brand {
      position: absolute;
      transition: top .5s, opacity .5s;
      top: 0;
      opacity: 1;

      width: 11rem;
      height: 100%;

      & > img {
        position: absolute;
      }

      @include media-breakpoint-up(lg) {
        &.hidden {
          top: -200%;
          opacity: 0;
        }
      }

      &.large {
        @include media-breakpoint-down(md) {
          display: none;
        }

        height: 11rem;
        width: 11rem;

        &:not(.hidden) {
          top: 50%;
        }
      }

      &.small {

        > * {
          display: flex;
          align-items: center;
        }

        img {
          margin-top: -3px;
        }
      }
    }
  }
}

.navbar-dark .navbar-nav .nav-link {
  padding-top: 1.5rem;
  padding-bottom: 0.3rem;

  color: #fff;

  border-bottom: 2px solid transparent;

  @include media-breakpoint-up(lg) {
    padding-top: 1.1rem;
    padding-bottom: 0.9rem;
  }

  &.active {
    border-bottom: 3px solid $red;
  }
}

.navbar-expand-lg {
  width: 100%;
}

.navbar-nav {
  width: 100%;

  li {
    list-style: none;
    text-transform: uppercase;
  }
  li:not(:first-child) {
    margin-left: 0;
    @include media-breakpoint-up(lg) {
      margin-left: 2.5rem;
    }
  }
}

header.fixed-top + * {
  margin-top: 60px;
}

header.fixed-top {
  padding-top: 2px;
  padding-bottom: 2px;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    padding-bottom: 0;
  }
}
